.profile-main-div{
    width: 100%;
    height: 100%;

    position: relative;
    padding-bottom: 30px;
    box-sizing: border-box;

    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px; */

}

.profile-main-div
.profile-main-content{
    /* margin-top: 16px; */

    width: 100%;
    box-sizing: border-box;
}

.profile-main-div
.profile-main-content
.head-cont{
    width: 100%;
    box-sizing: border-box;

    text-align: left;
    padding: 15px 20px;
    border-radius: 16px;

    background: #F2F2F2;
}

.profile-main-div
.profile-main-content
.main-info-container{
    width: 100%;
}

@media (max-width: 900px) {
    .profile-main-div{
        width: 100%;
        height: 100%;
    
        position: relative;
        /* padding-bottom: 100px;
        padding-top: 30px; */
        box-sizing: border-box;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    
    .profile-main-div
    .profile-main-content{
        /* margin-top: 50px; */
    
        width: 100%;
        /* padding-inline: 10%; */
        box-sizing: border-box;
    }
    
    .profile-main-div
    .profile-main-content
    .head-cont{
        width: 100%;
        box-sizing: border-box;
    
        text-align: left;
        padding: 15px 20px;
        border-radius: 16px;
    
        background: #F2F2F2;
    }
    
    .profile-main-div
    .profile-main-content
    .main-info-container{
        width: 100%;
    }
}