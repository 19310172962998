@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Supermercado+One&display=swap');

.supermercado {
  font-family: "Supermercado One", sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-green {
  -webkit-box-shadow: 0px 10px 32px -25px rgba(40,201,40,1);
  -moz-box-shadow: 0px 10px 32px -25px rgba(40,201,40,1);
  box-shadow: 0px 10px 32px -25px rgba(40,201,40,1);
}

.shadow-green-lg {
  -webkit-box-shadow: 0px 10px 50px -15px rgba(40,201,40,1);
  -moz-box-shadow: 0px 10px 50px -15px rgba(40,201,40,1);
  box-shadow: 0px 10px 50px -15px rgba(40,201,40,1);
}

.orange-gradient {
  background: rgb(251,51,26);
  background: linear-gradient(142deg, rgba(251,51,26,1) 0%, rgba(247,108,46,1) 100%);
}

@media (max-width: 768px) {
  .orange-gradient{
    background: transparent;
  }
}

.no-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  /* For Chrome, Safari, and other WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }
}