.tutorial-main-container-div{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.tutorial-main-title{
    color: #FB331A;
    text-wrap: nowrap;

    font-size: 2.75rem;
    font-weight: 500;

    letter-spacing: 0.5px;
}

.tutorial-main-paragraph{
    color: #5b5b5b;

    font-size: 1rem;
    line-height: 150%;
}

.tutorial-nav-div{
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tutorial-nav-div
.nav-btn{
    border: none;
    background-color: transparent;
    color: #5b5b5b;

    font-size: 1.5625rem;

    cursor: pointer;
}

.tutorial-nav-div
.tutorial-pagination{
    width: fit-content;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.tutorial-nav-div
.tutorial-pagination
.page-dot{
    width: 0.8rem;
    aspect-ratio: 1/1;
    border-radius: 50%;

    background-color: #929292;
    cursor: pointer;
}

.tutorial-nav-div
.tutorial-pagination
.active-dot{
    width: 2rem;
    height: 0.8rem;
    border-radius: 0.4rem;
}

@media (max-width: 1100px) { 
    .tutorial-main-title{
        color: #000;
        
        font-size: 1.875rem;
        font-weight: 500;
        
        letter-spacing: 0.5px;
        padding-bottom: 2.69rem;
    }

    .tutorial-main-paragraph{
        margin-bottom: 0rem;
        color: #000;

        font-size: 0.9375rem;
        line-height: 150%;
    }

    .tutorial-nav-div{
        width: 100%;

        margin-top: auto;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tutorial-nav-div
    .nav-btn{
        border: none;
        background-color: transparent;
        color: #000;

        font-size: 1.5625rem;

        cursor: pointer;
    }

    .tutorial-nav-div
    .tutorial-pagination{
        width: fit-content;
        height: fit-content;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
    }

    .tutorial-nav-div
    .tutorial-pagination
    .page-dot{
        width: 0.8rem;
        aspect-ratio: 1/1;
        border-radius: 50%;

        background-color: #fff;
        cursor: pointer;
    }

    .tutorial-nav-div
    .tutorial-pagination
    .active-dot{
        width: 2rem;
        height: 0.8rem;
        border-radius: 0.4rem;
    }
}